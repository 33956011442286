export const getBannerPlacementType = () => 'banners'

/**
 * @return {Object}
 * @param item
 */
export const getBannerPlacementData = (item, itemIndex = null) => {
  if (item.content) {
    return {
      content: {
        ...item.content,
      },
    }
  }

  let campaign = null

  if (item.source) {
    const {
      source: {
        promoActions,
        promoActionsTitles,
        salesrules,
        salesrulesTitles,
      },
    } = item

    if (salesrules?.length) {
      campaign = 'Sales rule'

      if (salesrulesTitles) {
        const id = salesrules[0]
        if (id && salesrulesTitles[id]) {
          campaign = salesrulesTitles[id]
        }
      }
    } else if (promoActions?.length) {
      campaign = 'Promo action'

      if (promoActionsTitles) {
        const id = promoActions[0]
        if (id && promoActionsTitles[id]) {
          campaign = promoActionsTitles[id]
        }
      }
    }
  }

  return {
    content: {
      source: 'eshop',
      name: item.name || item.title || 'Banner',
      position: `${itemIndex || item.position || item.priority || 1}`,
      ...(campaign && { campaign }),
      kind: 'np',
    },
  }
}
