<template>
  <div class="form-group">
    <div class="d-flex">
      <label
        v-if="label"
        :for="id"
        :class="{'required': required}"
      >
        {{ label }}
        <span v-if="optional">({{ $t('base.Optional') }})</span>
      </label>
    </div>
    <div
      :class="classes"
      class="position-relative d-flex"
    >
      <input
        v-if="staticInput"
        type="text"
        :value="staticInput"
        class="form-control mr-2 form-control-prefix"
        disabled
      >
      <input
        :id="id"
        ref="input"
        v-model="innerValue"
        :step="step"
        :type="!isPasswordVisible ? type : 'text'"
        :class="[classes, validityClass, {'is-shifted': type === 'password'}]"
        :aria-describedby="describedby"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :data-test-id="dataTestId"
        :required="required"
        lang="en"
        class="form-control"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keyup="onKeyup"
      >
      <div
        v-if="type === 'password'"
        class="showPasswordIcon position-absolute"
        :class="{hide: isPasswordVisible}"
        @click="showPassword"
      >
        <svg
          class="icon close__icon icon-xl"
          aria-hidden="true"
        >
          <use v-show="!isPasswordVisible" xlink:href="@nsf/ui-library/assets/icons/icons.svg#eye-slash" />
          <use v-show="isPasswordVisible" xlink:href="@nsf/ui-library/assets/icons/icons.svg#eye" />
        </svg>
      </div>
      <div
        v-if="isValidityTriggered"
        :class="{'is-shifted': type === 'password', 'valid-icon': isValidInput, 'invalid-icon': !isValidInput}"
      />
    </div>
    <slot name="infoBellow" />

    <template v-if="!isBeingEdited && validations.$error">
      <div
        v-for="(error, index) in errors"
        :key="`${index}-${error.label}`"
        class="invalid-feedback d-block"
        :data-test-id="`${error.param}-feedback`"
      >
        <span v-html="error.label" />
      </div>
    </template>
  </div>
</template>

<script>
import IsFormInput from '../../mixins/IsFormInput.js'

export default {
  mixins: [IsFormInput],

  props: {
    value: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    dataTestId: {
      type: String,
      default: null,
    },
    staticInput: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
.form-control-prefix {
  width: 15%;
}

label.required::after {
  content: '*';
  color: $red-600;
}
</style>
