import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import {
  isArray, isNullish, isString, isObject,
} from '@nsf/core/Utils.js'
import { hashCode } from '@nsf/utils/StringUtils.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { useDrmaxPackage } from '@nsf/use/composables/useDrmaxPackage.js'

const { version } = useDrmaxPackage()

const {
  rootConfig: {
    navigation,
  },
} = useAppConfig()

const {
  public: {
    appUrl,
    imageFormats,
  },
} = useRuntimeConfig()

/**
 * @typedef {Object} ImageOptions
 * @property {string} [path] - URL to the image
 * @property {string} [m2] - URI to the image in Magento2
 * @property {string} [nsf] - URI to the image in NSF
 * @property {number} [w] - width of the resized image
 * @property {number} [h] - height of the final image
 * @property {string} [fit=cover] - image fit and alignment (default: cover) - https://sharp.pixelplumbing.com/api-resize
 * @property {string} [format=jpg] - format of the final image
 * @property {boolean} [version=false] - add NSF version to image query to flush cache after release
 */

export const magentoImagesPath = '/media/catalog/product'

/**
 * Returns formated URL to retrieve image via NSF image resizer
 *
 * @param {ImageOptions} opts
 * @returns {string}
 */
export const image = (opts) => {
  // CloudFlare needs a format in the URL to know it's an image and cache it
  if (!opts.format) {
    // Get the first one as default
    const [defaultFormat] = imageFormats.split(',')
    // eslint-disable-next-line no-param-reassign
    opts.format = defaultFormat
  }

  // eslint-disable-next-line no-use-before-define
  const query = objectToQuery({
    ...opts,
    version: undefined, // 'version' is only indicator if the image should be versioned
    ...(opts.version ? { v: version } : {}),
    _media: undefined, // '_media' is used for LazyImage. We don't want it pulling image url
  })

  // We create hash from the query to uniqly identify the image in CloudFlare cache
  const hash = hashCode(query)

  return `/_i/${hash}.${opts.format}?${query}`
}

/**
 * Returns true if both urls have same domain
 *
 * @param {string} urlA
 * @param {string} urlB
 * @returns {boolean}
 */
export const domainsMatch = (urlA, urlB) => {
  const regex = /^https?:\/\/([^/:]+)/i

  const matchA = urlA.match(regex)
  const matchB = urlB.match(regex)

  if (!isArray(matchA) || isArray(matchB)) {
    return false
  }

  const domainA = matchA[1]
  const domainB = matchB[1]

  if (isNullish(domainA) || isNullish(domainB)) {
    return false
  }

  return domainA === domainB
}

/**
 * Transforms URL string query to an object
 *
 * @param {string} query
 * @returns {object}
 */
export const queryToObject = (query) => {
  if (!isString(query)) {
    return {}
  }
  if (query.startsWith('?')) {
    return queryToObject(query.slice(1))
  }

  return query
    .replace(/#.*/, '')
    .split('&')
    .map((param) => param.split('='))
    .map(([key, value]) => [decodeURIComponent(key), decodeURIComponent(value)])
    // eslint-disable-next-line no-shadow
    .reduce((query, [key, value]) => {
      if (isNullish(query[key])) {
        // eslint-disable-next-line no-param-reassign
        query[key] = value
      } else if (isArray(query[key])) {
        query[key].push(value)
      } else {
        // eslint-disable-next-line no-param-reassign
        query[key] = [query[key], value]
      }

      return query
    }, {})
}

export const objectToQuery = (object) => Object.keys(object)
  .filter((key) => object[key] !== undefined)
  .map((key) => {
    if (isArray(object[key])) {
      return object[key]
        .map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')
    } if (isObject(object[key])) {
      return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(object[key]))}`
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
  })
  .join('&')

/**
 * Creates queryString from an object
 *
 * @param {object} o
 * @returns {string}
 */
export const createQueryString = (o) => {
  if (!isObject(o)) {
    return ''
  }

  return Object.entries(o)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, v]) => !isNullish(v))
    .map(([k, v]) => ((isObject(v)) ? [k, JSON.stringify(v)] : [k, v]))
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&')
}

export const patchQuery = (query, patch) => {
  if (isString(query)) {
    return patchQuery(queryToObject(query), patch)
  }
  return objectToQuery({ ...query, ...patch })
}

export const splitQueryFromUrl = (url) => url.split('?')[1] || null

export const getPageNumber = (url) => {
  const query = splitQueryFromUrl(url)
  const page = queryToObject(query)?.page
  return page ? Number(page) : 1
}

export const getUrlKey = (url) => {
  const urlKey = url.startsWith('/') ? url.slice(1) : url
  return urlKey.split('?')[0]
}

export const checkUrlStartsWith = (url, urlArray) => {
  // eslint-disable-next-line no-use-before-define
  const cleanUrl = sliceSlashFromUrl(url)
  for (const i of urlArray) {
    // eslint-disable-next-line no-use-before-define
    const item = sliceSlashFromUrl(i)
    if (cleanUrl === item || cleanUrl.split('/')[0].startsWith(item)) {
      return true
    }
  }
  return false
}

export const sliceSlashFromUrl = (url) => {
  const urlClearedEnd = url.endsWith('/') ? url.slice(0, -1) : url
  return urlClearedEnd.startsWith('/') ? urlClearedEnd.slice(1) : urlClearedEnd
}

/**
 * Removes hash (fragment) part (e.g. `#heading-1`) which is used for anchor navigation.
 * @param url
 */
export const sliceHashPartFromUrl = (url) => url.replace(/#.*$/, '')

export const sliceQueryPartFromUrl = (url) => url.replace(/\?.*$/, '')

export const toRelative = (url) => {
  // Make all paths be relative ones (except for external links)
  if (!isString(url) || url === '#') {
    return '/'
  }

  // HACK - To make absolute URLs work for NSF it is needed to convert it to relative one for current environment url and stable predefined environments (for local development)
  // eslint-disable-next-line no-use-before-define
  const environmentURLS = getEnvironmentURLS()
  // eslint-disable-next-line no-use-before-define
  const environmentsMatch = isEnvironmentMatch(url, environmentURLS)
  if (environmentsMatch) {
    // eslint-disable-next-line no-param-reassign
    url = url.replace(environmentsMatch[0], '')
    return (!url) ? '/' : url
  }

  // eslint-disable-next-line no-use-before-define
  if (isExternalLink(url)) {
    return url
  }

  if (!url.startsWith('/')) {
    return `/${url}`
  }

  return url
}

export const isExternalLink = (url) => /(http|https):\/\/.*/.exec(url)

export const isEnvironmentMatch = (url, environmentURLS) => {
  const environmentsPattern = `^${environmentURLS.reduce((current, next, index) => {
    const separator = index !== 0 ? '|' : ''
    return `${current}${separator}${next}`
  })}`
  return url.match(environmentsPattern)
}

export const getEnvironmentURLS = () => {
  const environmentURLS = [appUrl]
  const urlOverrides = navigation?.RELATIVE_URL_OVERRIDE || []
  return urlOverrides.length > 0 ? environmentURLS.concat(urlOverrides) : environmentURLS
}

/**
 * Normalizes the URL to be valid (from BE URLs come in a lot of valid and invalid forms)
 */
export const normalizeUrl = (url = '') => {
  // If URL is in absolute form, but leads to NSF, we normalize it to relative from
  if (appUrl && url.startsWith(appUrl)) {
    return url.replace(appUrl, '')
  }

  // These URLs don't need normalization
  if (url.startsWith('http')) {
    return url
  }
  if (url.startsWith('/')) {
    return url
  }
  if (url.startsWith('?')) {
    return url
  }
  if (url.startsWith('#')) {
    return url
  }
  if (url.startsWith('mailto:')) {
    return url
  }
  if (url.startsWith('tel:')) {
    return url
  }

  // Absolute URL has to start with http:// to be a valid anchor
  if (url.startsWith('www')) {
    return `http://${url}`
  }

  // If none of the conditions above triggered,
  // URL was probably meant to be relative be it is missing the leading slash
  return `/${url}`
}

export const normalizeMagentoImageUrl = (url = '') => {
  const normalizedUrl = normalizeUrl(url)
  const finalUrl = normalizedUrl.startsWith('/') ? normalizedUrl : `/${normalizedUrl}`

  return magentoImagesPath + finalUrl
}

/**
 * Determines if the link is external and should be treated as anchor <a> tag
 */
export const shouldUseNativeAnchor = (url, external = false, newWindow = false) => {
  // If Link is explicitly marked as external or to open in new window, we have to treat it as anchor
  if (external) {
    return true
  }
  if (newWindow) {
    return true
  }
  if (sliceSlashFromUrl(url).startsWith('_m2')) {
    return true
  }

  if (url.startsWith('tel:')) {
    return true
  }

  // If URL starts with http, its external and we treat it as anchor
  if (url.startsWith('http')) {
    return true
  }

  return false
}
