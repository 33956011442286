import { renderChildren } from '../index.js';
import { applyInlineStyles } from '../../../utils/PagebuilderStyleUtils.js';
export default {
  name: 'VImg',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    if (ctx.props.data['data-element'] === 'mobile_image') {
      return null;
    }
    var children = ctx.props.data.children || [];
    var data = {
      attrs: {
        alt: ctx.props.data.alt,
        opts: {
          path: ctx.props.data.src,
          w: ctx.props.data.width || 0,
          h: ctx.props.data.height || 0
        },
        width: ctx.props.data.width ? parseInt(ctx.props.data.width, 10) : undefined,
        height: ctx.props.data.height ? parseInt(ctx.props.data.height, 10) : undefined,
        isNotLazy: ctx.props.data.disabledLazy,
        imageStyle: applyInlineStyles(ctx.props.data, ctx.props.data.style, {
          width: true
        })
      }
    };
    return h('figure', {
      class: 'pagebuilder-img'
    }, [h('v-lazy-image', data, renderChildren(h, ctx, children))]);
  }
};