var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShown)?_c('v-portal',[_c('div',{ref:"modal",class:['modal', _vm.modalClass, {'no-padding': _vm.noPadding, 'half-padding': _vm.halfPadding}],attrs:{"role":"dialog","tabindex":"0","aria-modal":"true"},on:{"mousedown":_vm.onBgClick,"touchstart":_vm.onBgClick,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onClose.apply(null, arguments)}}},[_c('div',{class:[
        'modal-dialog modal-dialog-centered',
        _vm.size ? `modal-${_vm.size}` : '',
      ],attrs:{"role":"document","aria-label":_vm.ariaModalLabel}},[_c('div',{staticClass:"modal-content"},[_vm._t("modal-header",function(){return [(!_vm.noHeader)?_c('div',{class:[
              'modal-header',
              {
                'text-white': _vm.isSuccess || _vm.isError,
                'bg-success': _vm.isSuccess,
                'bg-danger': _vm.isError,
              }
            ]},[_c('h1',{staticClass:"modal-title h1 font-weight-light"},[(_vm.title)?_c('span',[_vm._v("\n                "+_vm._s(_vm.title)+"\n              ")]):_vm._t("header")],2),_vm._v(" "),(_vm.canDismiss)?_c('v-button',{attrs:{"variant":"secondary","aria-label":_vm.$t('base.Close')},on:{"click":_vm.onClose},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"xlink:href":require("@nsf/ui-library/assets/icons/essentials.svg") + "#close"}})])]},proxy:true}],null,false,3113937215)}):_vm._e()],1):_vm._e()]}),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.noHeader && _vm.canDismiss)?_c('v-button',{class:[
              'close align-self-center',
              {
                'text-white': _vm.isSuccess || _vm.isError,
                'm-3 p-3 bg-white shadow': _vm.noPadding,
                'btn': _vm.isDismissAButton,
              }
            ],attrs:{"variant":"secondary","aria-label":"Close"},on:{"click":_vm.onClose},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"xlink:href":require("@nsf/ui-library/assets/icons/essentials.svg") + "#close"}})])]},proxy:true}],null,false,3113937215)}):_vm._e(),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer"},[_vm._t("footer")],2):_vm._e()],2)]),_vm._v(" "),_c('div',{attrs:{"tabindex":"0"},on:{"focus":function($event){$event.preventDefault();return _vm.$refs.modal.focus()}}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }