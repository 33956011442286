import { isString } from '@nsf/core/Utils.js'

export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    describedby: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    register: {
      type: Boolean,
      default: true,
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
    validationErrors: {
      type: Object,
      default: () => ({}),
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
    step: {
      type: Number,
      default: 1,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },

  inject: ['form'],

  data() {
    return {
      innerValue: '',
      wasValidatedByInteraction: false,
      isPasswordVisible: false,
      isBeingEdited: false,
    }
  },

  computed: {
    async isValid() {
      if (!this.validations) {
        return true
      }

      if (this.validations.$pending) {
        await this.waitForValidation()
      }

      return !this.validations.$invalid
    },

    errors() {
      if (this.isBeingEdited || !this.validations?.$params || !this.validations.$dirty) {
        return []
      }

      const errors = []
      let generalErrorOnly = false
      for (const param of Object.keys(this.validations.$params)) {
        if (this.validations[param]) {
          continue
        }

        if (this.validationErrors[param]) {
          errors.push({
            label: this.validationErrors[param],
            param,
          })
        } else if (this.$te(`validations.${param}`)) {
          errors.push({
            label: this.$t(`validations.${param}`),
            param,
          })
        } else {
          generalErrorOnly = true
        }

        if (param === 'required' || param === 'requiredIf') {
          return errors.length > 0
            ? errors
            : [
                {
                  label: this.$t('validations.required'),
                  param,
                },
              ]
        }
      }

      if (errors.length === 0 && generalErrorOnly) {
        errors.push({
          label: this.$t('validations.Field contains errors'),
          param: 'generalError',
        })
      }

      return errors
    },

    validityClass() {
      if (!this.wasValidatedByInteraction || !this.validations.$dirty) {
        return ''
      }

      if (this.validations.$invalid) {
        return 'is-invalid'
      }

      return 'is-valid'
    },

    isValidInput() {
      return this.validityClass === 'is-valid'
    },

    isValidityTriggered() {
      return this.validations.$dirty && this.wasValidatedByInteraction
    },
  },

  created() {
    if (this.register && this.form) {
      this.form.registerInput(this)
    }

    if (this.innerValue !== '') {
      this.$emit(
        'input',
        isString(this.innerValue) ? this.innerValue?.trim() : this.innerValue,
      )
    }
  },

  beforeDestroy() {
    if (this.register && this.form) {
      this.form.unregisterInput(this)
    }
  },

  methods: {
    showPassword() {
      this.isPasswordVisible = !this.isPasswordVisible
    },

    async onInput(e) {
      const { value } = e.target

      this.$emit(
        'input',
        value,
      )
    },

    async onBlur() {
      this.isBeingEdited = false

      if (this.validations.$touch) {
        this.validations.$touch()
      }

      this.$emit(
        'blur',
        this,
      )

      this.$parent.$emit('blur')

      this.wasValidatedByInteraction = true
    },

    onFocus() {
      this.isBeingEdited = true
    },

    onKeyup() {
      this.$emit(
        'keyup',
        this,
      )
    },

    setValue(value) {
      this.innerValue = value
    },

    getValue() {
      return this.innerValue
    },

    clearValidityClasses() {
      this.validations.$reset()
    },
  },

  watch: {
    'value': {
      handler(val) {
        this.innerValue = val
      },
      immediate: true,
    },
    'validations.$error': {
      handler(val) {
        if (val) {
          this.wasValidatedByInteraction = true
        }
      },
      immediate: true,
    },
  },
}
