import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.exec.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { applyInlineStyles, createElementId, createStyleElementContent, extractDeclarationFromStyle, setFlexAlignment } from '@nsf/base/utils/PagebuilderStyleUtils.js';
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js';
import { mdMax } from '@nsf/ui-library/assets/styles/responsive.scss';
import { findDataElement } from '../components/PageBuilder/index.js';
import LazyImage from '../shared/LazyImage.vue';
var _useRuntimeConfig = useRuntimeConfig(),
  imageFormats = _useRuntimeConfig.public.imageFormats;
var isNotLazy = function isNotLazy(imageDesktop) {
  // if image should be eager (it is first image), we will apply it
  if (imageDesktop.disabledLazy) {
    return true;
  }

  // if image has a loading property set, we will use it
  if (imageDesktop.loading) {
    return imageDesktop.loading === 'eager';
  }
  return false;
};

/*
  Defining base width size {w} for possibility to get several differently sized images,
  browser will choose the right one causing reduction of downloaded data
  e.g. For device-pixel-ration=2 and viewport < 400, it will download the 800w image from srcset
  */
var generateImageOpts = function generateImageOpts(imageDesktop, imageMobile, isGif) {
  var opts = [_objectSpread(_objectSpread({
    path: imageDesktop.src,
    w: imageDesktop.width,
    h: imageDesktop.height
  }, (imageMobile === null || imageMobile === void 0 ? void 0 : imageMobile.src) && {
    media: '(min-width: 768.1px)'
  }), isGif && {
    format: 'gif'
  })];
  if (imageMobile !== null && imageMobile !== void 0 && imageMobile.src) {
    opts.unshift(_objectSpread({
      path: imageMobile.src,
      w: imageMobile.width,
      h: imageMobile.height,
      media: "(max-width: ".concat(mdMax, "px)")
    }, isGif && {
      format: 'gif'
    }));
  }
  return opts;
};
export default {
  name: 'PagebuilderImage',
  functional: true,
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var _findDataElement$chil, _findDataElement, _findDataElement$chil2, _findDataElement$chil3, _ctx$props$data, _ctx$props$data2, _imageDesktop$alt, _imageDesktop$alt2;
    var imageDesktop = findDataElement('desktop_image', ctx.props.data);
    if (!imageDesktop) {
      return null;
    }
    var imageMobile = findDataElement('mobile_image', ctx.props.data);
    var link = findDataElement('link', ctx.props.data);
    var caption = (_findDataElement$chil = (_findDataElement = findDataElement('caption', ctx.props.data)) === null || _findDataElement === void 0 ? void 0 : (_findDataElement$chil2 = _findDataElement.children) === null || _findDataElement$chil2 === void 0 ? void 0 : (_findDataElement$chil3 = _findDataElement$chil2[0]) === null || _findDataElement$chil3 === void 0 ? void 0 : _findDataElement$chil3.html) !== null && _findDataElement$chil !== void 0 ? _findDataElement$chil : '';
    var componentTag = link ? 'v-link' : 'div';
    var isGif = /(?:\.([^.]+))?$/.exec(imageDesktop.src)[1] === 'gif';
    var desktopStyle = applyInlineStyles(ctx.props.data, ctx.props.data.style, {
      width: true
    });
    var mobileStyle = (_ctx$props$data = ctx.props.data) === null || _ctx$props$data === void 0 ? void 0 : _ctx$props$data['mobile-style'];
    var elementId = createElementId((_ctx$props$data2 = ctx.props.data) === null || _ctx$props$data2 === void 0 ? void 0 : _ctx$props$data2['data-pb-style']);
    var styleContent = createStyleElementContent(elementId, desktopStyle, mobileStyle);
    var imageStyle = applyInlineStyles(imageDesktop, imageDesktop === null || imageDesktop === void 0 ? void 0 : imageDesktop.style, {
      padding: true,
      margin: true,
      width: true
    });
    var _extractDeclarationFr = extractDeclarationFromStyle('text-align', ctx.props.data.style),
      declarationValue = _extractDeclarationFr.declarationValue;
    var outerClassName = "".concat(setFlexAlignment(declarationValue), " ").concat(ctx.props.data.class || '', " pagebuilder-image");

    /*
    Defining base width size {w} for possibility to get several differently sized images,
    browser will choose the right one causing reduction of downloaded data
    e.g. For device-pixel-ration=2 and viewport < 400, it will download the 800w image from srcset
    */
    var opts = generateImageOpts(imageDesktop, imageMobile, isGif);
    var lazyImageData = {
      props: {
        opts: opts,
        alt: (_imageDesktop$alt = imageDesktop.alt) !== null && _imageDesktop$alt !== void 0 ? _imageDesktop$alt : '',
        title: (_imageDesktop$alt2 = imageDesktop.alt) !== null && _imageDesktop$alt2 !== void 0 ? _imageDesktop$alt2 : '',
        formats: [imageFormats].concat(_toConsumableArray(isGif ? ['gif'] : ['jpeg'])).join(','),
        multiple: [1, 2, 4, 6],
        width: imageDesktop.width ? parseInt(imageDesktop.width, 10) : null,
        height: imageDesktop.height ? parseInt(imageDesktop.height, 10) : null,
        imageStyle: imageStyle,
        isNotLazy: isNotLazy(imageDesktop)
      }
    };
    var lazyImageElement = h(LazyImage, lazyImageData, []);
    var figureElement = h('figure', {
      attrs: {
        id: elementId
      }
    }, [lazyImageElement]);
    var innerElementChildren = [figureElement];
    if (caption) {
      var figcaptionElement = h('figcaption', {
        class: 'mb-2 caption-size',
        domProps: {
          innerHTML: caption
        }
      }, []);
      innerElementChildren.push(figcaptionElement);
    }
    var innerElement = h(componentTag, {
      attrs: {
        target: link === null || link === void 0 ? void 0 : link.target,
        to: link === null || link === void 0 ? void 0 : link.href
      }
    }, innerElementChildren);
    var outerElementChildren = [innerElement];
    if (styleContent) {
      outerElementChildren.push(h('style', {
        domProps: {
          innerHTML: styleContent
        }
      }));
    }
    return h('div', {
      class: outerClassName
    }, [outerElementChildren]);
  }
};