import { $PlacementView } from '@nsf/gtm/providers/PlacementViewProvider.js'

export default {
  inject: {
    [$PlacementView]: {
      default: {},
    },
  },

  computed: {
    contentData() {
      return this.product || this.option || {}
    },

    placementData() {
      return {
        pageType: this.$PlacementView.computedPageType,
        name: this.$PlacementView.computedName,
        detail: this.$PlacementView.computedDetail,
        type: this.$PlacementView.computedPlacementType || 'placementContent',
      }
    },
  },

  methods: {
    getAdditionalTrackingProps(ignoreStoreValues = false) {
      if (!ignoreStoreValues) {
        const sourceData = this.$store.getters['catalog/getProductSourceBySku'](this.contentData.sku)

        if (sourceData) {
          return {
            placement: sourceData.placement,
          }
        }
      }

      return {
        placement: {
          ...this.placementData,
          content: {
            ...this.getPlacementContent(),
          },
        },
      }
    },

    getPlacementObjectType() {
      return 'products'
    },

    getPlacementObjectData() {
      return {
        ...this.contentData,
        position: this.position,
        placementContent: this.getPlacementContent(),
      }
    },

    cleanObject(obj) {
      if (!obj) {
        return {}
      }
      return Object.fromEntries(
        Object.entries(obj).filter(([, value]) => value != null),
      )
    },

    getPlacementContent() {
      if (this.contentData.content) {
        return {
          ...this.cleanObject(this.contentData.content),
          position: `${this.position}`,
        }
      }
      const sourceMap = {
        'Magento': {
          source: 'eshop',
          personalized: false,
        },
        'Meiro - Not Found': {
          source: 'meiro',
          personalized: false,
        },
        'Meiro - Found': {
          source: 'meiro',
          personalized: true,
        },
        'Persoo': {
          source: 'persoo',
          personalized: true,
        },
      }
      const source = sourceMap[this.contentData.loaderLabel]?.source || 'eshop'
      const kind = sourceMap[this.contentData.loaderLabel]?.personalized ? 'p' : 'np'
      return {
        ...(this.contentData.contentCampaign && { campaign: this.contentData.contentCampaign }),
        ...(this.contentData.contentName && { name: this.contentData.contentName }),
        source,
        kind,
        position: `${this.position}`,
      }
    },
  },
}
