import logger from '@nsf/search/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { mapDataToHits, mapDataToSearches } from '../mappers/DataMapper.js'
// eslint-disable-next-line import/no-cycle
import {
  getUserToken,
  getItemType,
  isValidQuery,
  normalizeQuery,
} from '../utils/SearchUtils.js'

const { search: { searchService } } = useAppConfig()
const { public: { appUrl, searchServiceHost } } = useRuntimeConfig()

async function fetchData(endpoint, queryParams) {
  const userToken = getUserToken()
  if (userToken) {
    queryParams.push(`userToken=${userToken}`)
  }

  const url = `${searchServiceHost}/v1/${endpoint}?${queryParams.join('&')}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Tenant': searchService.tenant,
      'X-Store': searchService.storeId,
    },
  })

  if (response.status !== 200) {
    logger.error('fetch(%o, %o) failed: %o', endpoint, queryParams, response)
    return []
  }

  return await response.json()
}

export const sendStats = (query, delays) => {
  try {
    const userToken = getUserToken()

    fetch(`${searchServiceHost}/v1/stats`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Tenant': searchService.tenant,
        'X-Store': searchService.storeId,
      },
      body: JSON.stringify({
        delays: delays.join(','),
        // eslint-disable-next-line camelcase
        user_id: `${userToken}`,
        query,
      }),
    })
  } catch (e) {
    logger.error('stats failed: %s', e.message)
  }
}

export const searchAutocomplete = async (query) => {
  const data = {
    hits: [],
    totals: {},
  }

  try {
    const q = normalizeQuery(query)
    if (!isValidQuery(q)) {
      return data
    }

    const json = await fetchData('suggest', [`query=${encodeURIComponent(q)}`])
    const types = Object.keys(json.data)

    for (let i = 0; i < types.length; i++) {
      const typeHits = mapDataToHits(json.data[types[i]].items)
      data.hits = [...data.hits, ...typeHits]
      data.totals[getItemType(types[i])] = json.data[types[i]].total
    }
  } catch (e) {
    logger.error('suggest(%o) failed: %s', query, e.message)
  }
  return data
}

export const searchEndpoint = async (endpoint, query, queryParams = {}) => {
  try {
    const q = normalizeQuery(query)
    if (!isValidQuery(q)) {
      return {
        hits: [],
        total: 0,
      }
    }

    const fetchParams = [
      `query=${encodeURIComponent(q)}`,
    ]

    if (queryParams?.limit || parseInt(queryParams.limit) > 0) {
      fetchParams.push(`limit=${parseInt(queryParams.limit)}`)
    }

    if (queryParams?.offset || parseInt(queryParams.offset) >= 0) {
      fetchParams.push(`offset=${parseInt(queryParams.offset)}`)
    }

    if (queryParams?.excludeId) {
      fetchParams.push(`excludeId=${queryParams.excludeId}`)
    }

    if (queryParams?.fieldsToRetrieve) {
      fetchParams.push(`fieldsToRetrieve=${queryParams.fieldsToRetrieve}`)
    }

    if (queryParams?.withTotals) {
      fetchParams.push(`withTotals=${queryParams.withTotals}`)
    }

    const json = await fetchData(endpoint, fetchParams)

    const totals = {}
    if (json.totals) {
      const types = Object.keys(json.totals)

      for (let i = 0; i < types.length; i++) {
        totals[getItemType(types[i])] = json.totals[types[i]]
      }
    }

    return {
      hits: mapDataToHits(json?.data?.items || []),
      total: json?.data?.total || 0,
      totals,
      queryID: json?.data?.queryID || undefined,
    }
  } catch (e) {
    logger.error('search(%o, %o) failed: %s', query, queryParams, e.message)

    return {
      hits: [],
      total: 0,
    }
  }
}

export const getAnalyticsTopSearches = async () => {
  try {
    const response = await fetch(`${appUrl}/search-suggestion/top-searches`)
    if (response.status !== 200) {
      throw new Error(response.statusText)
    }

    const data = await response.json()

    return { searches: data.items ? mapDataToSearches(data.items) : [] }
  } catch (e) {
    logger.withTag('Suggester').error(' analyticsTopSearches failed: %s', e.message)
    return { searches: [] }
  }
}

export const getAnalyticsTopProducts = async () => {
  try {
    const response = await fetch(`${appUrl}/search-suggestion/top-products`)
    if (response.status !== 200) {
      throw new Error(response.statusText)
    }

    const data = await response.json()

    return { products: data.items ?? [] }
  } catch (e) {
    logger.withTag('AnalyticsTopProducts').error(' analyticsTopProducts failed: %s', e.message)
    return { products: [] }
  }
}
