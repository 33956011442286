/* eslint-disable no-shadow */
import { getCmsBlockByIdentifier } from '@nsf/base/repositories/CmsBlockRepository.js'
import { extractHtml } from '@nsf/base/components/PageBuilder/index.js'
import { isEmpty } from '@nsf/core/Utils.js'
import { setState } from '@nsf/core/Store.js'
import { useCountryFlags } from '@nsf/use/composables/useCountryFlags.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  catalog: {
    product: {
      infoBoxEnabled,
      detail: {
        showPriceInfo,
        showPriceNote,
        useFreeShippingThreshold,
      },
    },
  },
} = useAppConfig()

const {
  isCZ,
  isRO,
} = useCountryFlags()

export const state = () => ({
  priceInfo: null,
  priceNote: null,
  transportNote: null,
  infoBox: '',
  productSources: [],
  savedPositions: [],
  pdpEventsFired: false,
})

export const nuxtServerInit = async ({ state, dispatch }) => {
  const cmsBlocks = []

  if (!isCZ && showPriceInfo) {
    cmsBlocks.push(getCmsBlockByIdentifier('product_pricing_note_upper'))
  }

  if (showPriceNote) {
    cmsBlocks.push(getCmsBlockByIdentifier('product_pricing_note'))
  }

  if (!isRO && !useFreeShippingThreshold) {
    cmsBlocks.push(getCmsBlockByIdentifier('product_transport_note'))
  }

  if (infoBoxEnabled) {
    await dispatch('catalog/getInfoBox')
  }

  if (!isEmpty(cmsBlocks)) {
    const [{ cmsBlock }, priceNote, transportNote] = await Promise.all(cmsBlocks)

    state.catalog.priceInfo = cmsBlock ? extractHtml(cmsBlock.content) : null
    state.catalog.priceNote = priceNote?.cmsBlock ? priceNote.cmsBlock.content : null
    state.catalog.transportNote = transportNote?.cmsBlock ? transportNote.cmsBlock.content : null
  }
}

export const mutations = {
  setState,
  setPdpEventsFired(state, value) {
    state.pdpEventsFired = value
  },
  addProductSource(state, payload) {
    if (state.productSources.length >= 20) {
      state.productSources.shift()
    }

    const existedSourceIndex = state.productSources.findIndex(
      (product) => (payload.sku && product.sku === payload.sku)
    || (payload.slug && product.slug === payload.slug),
    )
    existedSourceIndex !== -1 && state.productSources.splice(existedSourceIndex, 1)

    state.productSources.push(payload)
  },
  savePosition(state, { fullPath, position }) {
    const posIndex = state.savedPositions.findIndex((item) => item.fullPath === fullPath)

    if (posIndex !== -1) {
      state.savedPositions[posIndex].position = position
    } else {
      state.savedPositions.push({ fullPath, position })

      if (state.savedPositions.length >= 20) {
        state.savedPositions.shift()
      }
    }
  },
}

export const actions = {
  async getInfoBox({ commit }) {
    const { cmsBlock } = await getCmsBlockByIdentifier('additional_info_box_for_product_cart')
    commit('setState', { infoBox: cmsBlock.content })
  },
}
export const getters = {
  savedPositions: (state) => state.savedPositions,
  getProductSourceBySku: (state) => (sku) => state.productSources.find((prod) => prod.sku === sku),
  getProductSourceBySlug: (state) => (slug) => state.productSources.find((prod) => prod.slug === slug),
  pdpEventsFired: (state) => state.pdpEventsFired,
}
